<template>
	<form class="login-form">
		<div class="login-form__header">
			Авторизация
		</div>
		<div class="login-form__input">
			<b-input
				block
				:error="error"
				label="Логин"
				v-model="login"
				@input="() => error = false"
			/>
		</div>
		<div class="login-form__input">
			<b-input
				block
				:error="error"
				password
				label="Пароль"
				v-model="password"
				@input="() => error = false"
			/>
		</div>
		<div v-if="error" class="login-form__info--error">
			Неправильно введен логин или пароль. 
Попробуйте снова или обратитесь 
к <span class="info__accent">администратору</span> если вы забыли свои 
данные для входа
		</div>
		<div class="login-form__submit">
			<b-button
				large
				block
				:error="error"
				@click.prevent="doLogin"
				:disabled="!login || !password"
			>
				Войти
			</b-button>
		</div>
		<div v-if="!error" class="login-form__info">
			Забыли свой пароль? Обратитесь к&nbsp;<span class="info__accent">администратору</span>
		</div>
		
		<div v-if="$route.query.state == 1" class="login-form__success">
			Вы успешно зарегистрированы! Теперь вы можете войти в свой профиль
		</div>
		<div v-else-if="!error" class="login-form__register">
			<router-link
				:to="{ name: 'Register' }"
			>
				<span class="info__accent">Зарегистрироваться</span>
			</router-link>
		</div>
	</form>
</template>

<script>
import input from "@/components/basic/input.vue";
import button from "@/components/basic/button.vue";

import api from "@/api"


export default {
	name: "b-form",
	components: {
		"b-input": input,
		"b-button": button,
	},
	data: () => ({
		login: '',
		password: '',
		loading: false,
		error: false
	}),
	methods: {
		doLogin(){
			this.$store.dispatch('auth/generateDevice_id', {login: this.login, password: this.password})
			api.login(this.login, this.password)
			.then((res) => {
				if(res.token){
					this.$store.commit('auth/setIsLoggedIn', true)
					this.$store.dispatch('projects/fetch')
					.then(res => {
						if(res.projects.length == 0){
							this.$router.push({name: 'Tutorial'});
							} else {
							this.$router.push({name: 'Games hub'});
						}
					})
				} else {
					throw res
				}
			})
			.catch(() => {
				this.error = true;
			})
		}
	},
	created(){
	}
}
</script>

<style scoped lang="sass">
.login-form
	&__header
		font-size: 28px
		font-weight: 600
		line-height: 36px
		margin-bottom: 16px

	&__input
		margin-bottom: 20px

	&__submit
		margin-bottom: 24px
	
	&__info
		font-size: 14px
		line-height: 20px
		color: $secondary-dark

	&__register
		font-size: 14px
		margin-top: 10px

		&--error
			margin-top: -8px
			color: $error
			margin-bottom: 24px
			.info__accent
				color: $error
	
	&__success
		margin-top: 15px
		background-color: $green-light-1
		color: $green-dark-1
		border-radius: 15px
		padding: 9px

.info__accent
	color: $main-dark
	text-decoration: underline
	cursor: pointer

@media screen and (min-width: $xs)
	.login-form__header
		text-align: center

</style>